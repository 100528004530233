html, body {
  height: 100%;
}
html {
  display: table;
  margin: auto;
}
body {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  background-color: #171941;
  vertical-align: middle;
  background-image: radial-gradient(#303030 1px, transparent 0);
  background-size: 10px 10px;
  background-position: -19px -19px;
  display: table-cell;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}


address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

li {
  display: inline-block;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 15px;
  padding-bottom: 15px;
}

li:hover {
  background-color: #171941;
}

li a {
  color: #FFFFFF;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 20px;
}

#tabbar {
  font-size: 20px;
  background-color: #e14eca;
  position: absolute;
  top: 0px;
  left: 25%;
  width: 50vw;
  border-radius: 5px;
}

.h1-main {
  color: #FFFFFF;
  font-weight: 600;
  font-family: 'IBM Plex Mono', monospace;
}

.apiData {
  font-size: 12px;
}

#featuredJobs {
  width: 60vw;
  display: flex;
}

#featuredJobsContainer {
  margin-top: 150px;
  background-color: #282b52;
  border-radius: 5px;
  padding: 10px;
}

#featuredJobsTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

#featuredJobsSeeMore {
  margin-top: 10px;
}

#mainHeader {
  color: #FFFFFF;
}

.jobList {
  background-color: #282b52;
  color: #9C9C9C;
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
}

#jobSearchOptions {
  position: fixed;
  top: 180px;
  background-color: #282b52;
  color: #FFFFFF;
  height: 100px;
  width: 80vw;
  left: 10%;
  border-radius: 5px;
  align-items: center;
  display: flex;
}

.job {
  height: auto;
  margin: 10px;
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 5px;
  transition: all .2s linear;
  padding-right: 10px;
  padding-left: 10px;
}



#googleJob {
  background-color: #2f813d;
  border: 3px solid #5aac67;
}
#googleJob:hover {
  background-color: #5aac67;
}
#amazonJob {
  background-color: #ad6614;
  border: 3px solid #ce8a3d;
}
#amazonJob:hover {
  background-color: #ce8a3d;
}
#appleJob {
  background-color: #525252;
  border: 3px solid #747373;
}
#appleJob:hover {
  background-color: #747373;
}
#metaJob {
  background-color: #1a1c7c;
  border: 3px solid #343483;
}
#metaJob:hover {
  background-color: #343483;
}

#jobTitle {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  color: #FFFFFF;
  margin-top: 20px;
}

.jobDetail {
  display: inline-block;
  font-size: 12px;
  margin-right: 30px;
  color: #FFFFFF;
  margin-top: 0px;
  margin-bottom: 0px;
}

.jobDetailDiv {
  display: inline-block;
  margin: auto;
  width: 90%;
  padding: 10px;
}

#jobURL {
  font-size: 16px;
}

#jobConfidenceGraphic {
  min-width: 10px;
  min-height: 10px;
  width: 10px;
  height: 10px;
  max-width: 10px;
  max-height: 10px;
  background-color: #87df95;
  border: 2px solid #080808;
  border-radius: 5px;
  display: block;
  margin-top: 3px;
}

.searchCheckbox {
  accent-color: #e14eca;
}

.searchCheckboxLabel {
  font-size: 16px;
  font-family: 'IBM Plex Mono', monospace;
  padding-right: 10px;
  margin: 5px;
}

.message {
  position: absolute;
}

#jobListTotal {
  position: absolute;
  top: 281px;
  left: 10%;
  display: flex;
  width: 80vw;
}

.genericSearchTextbox {
  border-radius: 5px;
  background: #171941;
  color: #FFFFFF;
  border: 3px solid #9b6493;
  transition: all .2s linear;
  outline: 0;
  height: 30px;
  font-size: 16px;
  text-align: center;
  font-family: 'IBM Plex Mono', monospace;
}

.genericSearchTextbox:focus {
  border: 3px solid #e14eca;
  background: #FFFFFF;
  color: #222222;
}

.genericSearchTextbox:hover {
  border: 3px solid #e14eca;
}


.jobSearchOptionsRow {
  display: flex;
  width: 100%;
}

.jobSearchBlock {
  width: 20%;
  display: inline-block;
}

#jobSearchBlockFirst {
  padding-left: 10%;
}

.genericSearchLabel {
  font-size: 16px;
  display: grid;
}

.standardButton {
  border: 3px solid #685465;
  transition: all .2s linear;
  background: #e14eca;
  height: 40px;
  width: 120px;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  font-family: 'IBM Plex Mono', monospace;
  cursor: pointer;
  color: #FFFFFF;
}

.standardButton:hover {
  border: 3px solid #e14eca;
}

.nextPrevButton {
  border: 3px solid #685465;
  transition: all .2s linear;
  background: #e14eca;
  height: 40px;
  width: 120px;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  font-family: 'IBM Plex Mono', monospace;
  cursor: pointer;
  color: #FFFFFF;
  margin: 5px;
}

.nextPrevButton:hover {
  border: 3px solid #e14eca;
}

#filterDropDown {
  border: 3px solid #685465;
  transition: all .2s linear;
  background: #e14eca;
  height: 40px;
  width: 180px;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  font-family: 'IBM Plex Mono', monospace;
  color: #FFFFFF;
  cursor: pointer;
}

#filterDropDown:hover {
  border: 3px solid #e14eca;
}

#filterDropDown:focus {
  border: 3px solid #e14eca;
}

.jobEstimatedSalaryBlock {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#logoTabbar {
  width: 50px;
  height: 50px;
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

#updateOverlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  opacity: 0.98;
  top: 100px;
  left: 0px;
  z-index: 1;
  text-align: center;
  background-color: #171941;
  vertical-align: middle;
  background-image: radial-gradient(#303030 1px, transparent 0);
  background-size: 10px 10px;
  background-position: -19px -19px;
}

#updateOverlayText {
  color: #FFFFFF;
  margin: 50px;
  margin-top: 20%;
}

#aboutPageText {
  background-color: #282b52;
  border-radius: 5px;
  padding: 20px;
  width: 80vw;
  font-size: 16px;
  text-align: left;
}

.faqQuestion {
  transition: .2s;
  background-color: #282b52;
  border-radius: 5px;
  cursor: pointer;
  width: 80vw;
  padding: 20px;
}

.faqQuestion:hover {
  transition: .2s;
  background-color: #2f3253;
}

.faqAnswer {
  transition: .2s;
  background-color: #525252;
  border-radius: 5px;
  font-size: 16px;
  width: 80vw;
  padding: 20px;
}

.faqAnswerClose {
  transition: .2s;
  border-radius: 5px;
  font-size: 0px;
  width: 80vw;
}